import { Component } from '@angular/core';

@Component({
  selector: 'app-changelog-list-button',
  templateUrl: './changelog-list-button.component.html',
  styleUrls: ['./changelog-list-button.component.scss'],
})
export class ChangelogListButtonComponent {
  public changelogPageUrl: string;

  constructor() {
    this.changelogPageUrl = '/changelog/list';
  }
}
